<template>
    <div>
      <div
      style="
        height: 100vh;
        contain: content;
        background: var(--v-greyRaised-base) !important;
      "
    >
    <v-toolbar flat dense color="secondary" height="36px">
      <b style="font-size: 16px">Products & Services</b>
    </v-toolbar>
    <div class="contents">
        <div class="mainContentWrap">
          <v-tabs height="30px" v-model="currentTab" class="tabby" style="">
            <v-tab
              v-for="(tab, index) in tabs"
              :key="index"
              :href="`#${tab.value}`"
            >
              {{ tab.name }}
            </v-tab>
            </v-tabs>
            <div class="mainContent">
            <v-tabs-items
              v-model="currentTab"
              style="height: inherit; width: 100%"
            >
              <v-tab-item style="height: inherit; width: 100%" value="management">
              <Management />
              </v-tab-item>
              <v-tab-item style="height: inherit; width: 100%" value="report">
              <Report />
              </v-tab-item>
              </v-tabs-items>
              </div>
            </div>
</div>
</div>
    </div>
</template>
<script>
import Management from '../components/ProductServices/Management.vue';
import Report from '../components/ProductServices/Report.vue';
export default {
  components: {
    Management,
    Report
  },
  data: ()=>({
    currentTab: 'management',
    tabs: [
      { name: 'Product Management', value: 'management' },
      // { name: 'Reports', value: 'report' },

    ]
  })
}
</script>
<style scoped>
.tabby ::v-deep.theme--dark.v-tabs .v-tabs-bar,
.tabby ::v-deep .v-tabs-bar__content,
.tabby ::v-deep .v-tab {
  background: var(--v-greyDarker-base) !important;
  border-radius: 0px;
}
</style>