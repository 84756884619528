<template>
    <v-container>
      <v-row>
        <v-col cols="8">
          <!-- Chart displaying total sales per product -->
          <ag-charts-vue
            style="height:400px; width: 100%"
            :options="chartOptions"
          ></ag-charts-vue>
          <!-- Data table showing detailed sales data using ag-grid-vue -->
          <ag-grid-vue
            style="width: 100%; height: 400px;"
            :autoSizeStrategy="{
        type: 'fitGridWidth',
        defaultMinWidth: 150
      }"
            :columnDefs="columnDefs"
            :rowData="tableData"
            :gridOptions="gridOptions"
          ></ag-grid-vue>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <!-- Additional content if needed -->
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import { AgChartsVue } from 'ag-charts-vue';
  import { AgGridVue } from 'ag-grid-vue';
  
  export default {
    components: {
      AgChartsVue,
      AgGridVue,
    },
    data: () => ({
      columnDefs: [
        { headerName: 'Week', field: 'week', sortable: true, filter: true },
        { headerName: 'Product', field: 'product', sortable: true, filter: true },
        { headerName: 'Total Sales', field: 'sales', sortable: true, filter: true },
      ],
      tableData: [],
      chartOptions: {},
      loadingChartComplete: false,
      report: [],
      gridOptions: {
        defaultColDef: {
          resizable: true,
          sortable: true,
          filter: true,
        },
      },
    }),
    created() {
      this.load();
    },
    methods: {
      async load() {
        try {
          this.report = await this.$API.getProductServiceSaleReport();
          this.processData();
        } catch (error) {
          console.error('Error fetching report data:', error);
        }
      },
      processData() {
        // Step 1: Aggregate total sales per product per week
        const weekProductSales = {};
        const productNamesSet = new Set();
  
        this.report.forEach((item) => {
          const week = item.week;
          const productName = item.productService.name;
          const total = parseFloat(item.total);
  
          // Add product name to the set of all product names
          productNamesSet.add(productName);
  
          if (!weekProductSales[week]) {
            weekProductSales[week] = {};
          }
  
          if (weekProductSales[week][productName]) {
            weekProductSales[week][productName] += total;
          } else {
            weekProductSales[week][productName] = total;
          }
        });
  
        // Step 2: Prepare data for the table
        this.tableData = [];
        for (const week in weekProductSales) {
          for (const productName in weekProductSales[week]) {
            this.tableData.push({
              week,
              product: productName,
              sales: weekProductSales[week][productName],
            });
          }
        }
  
        // Step 3: Prepare data for the chart
        // Get a sorted list of weeks
        const weeks = Object.keys(weekProductSales).sort((a, b) => {
          // Custom sort function to order weeks chronologically
          const [yearA, weekA] = a.split(' - W').map(Number);
          const [yearB, weekB] = b.split(' - W').map(Number);
          if (yearA !== yearB) return yearA - yearB;
          return weekA - weekB;
        });
  
        // Get a list of product names
        const productNames = Array.from(productNamesSet);
  
        // Build chart data array
        const chartData = weeks.map((week) => {
          const dataPoint = { week };
          productNames.forEach((productName) => {
            dataPoint[productName] = weekProductSales[week][productName] || 0;
          });
          return dataPoint;
        });
  
        // Step 4: Configure the chart options
        const series = productNames.map((productName) => ({
          type: 'bar', // Or 'column' if you prefer vertical bars
          xKey: 'week',
          yKey: productName,
          yName: productName,
          stacked: false, // Set to true if you want a stacked bar chart
        }));
  
        this.chartOptions = {
          type: 'cartesian', // Ensure the chart type is specified
          data: chartData,
          title: {
            text: 'Product Sales by Week',
          },
          theme: 'ag-vivid-dark',
          series: series,
          axes: [
            {
              type: 'category',
              position: 'bottom',
              title: { text: 'Week' },
              label: {
                rotation: 45,
              },
            },
            {
              type: 'number',
              position: 'left',
              title: { text: 'Total Sales' },
            },
          ],
          legend: { enabled: true },
        };
      },
    },
  };
  </script>

  